import { CSVLink } from "react-csv";
import { DownloadIcon } from '@radix-ui/react-icons'; 

interface Props {
    transactions: any[],
    firstName: string
}

const isValidDate = (date: any) => {
    return !isNaN(Date.parse(date));
};

const formatNumber = (value: any, decimals: number = 2) => {
    return typeof value === 'number' ? value.toFixed(decimals) : "0.00";
};

export const ReportButton = ({ transactions, firstName }: Props) => {
    let koinlyTransactionsList: any[] = [];
    let koinly1Transactions: any = {};
    let koinly2Transactions: any = {};
    let koinly3Transactions: any = {};

    if (transactions.length > 0) {
        transactions.forEach(t => {
            let label: string;
            if (t.leftSideLabel === "CAD") {
                label = "Buy Stablecoin";
            } else {
                label = "Sell Stablecoin";
            }

            const date = isValidDate(t.date) ? new Date(t.date).toLocaleString("en-CA", { dateStyle: "short", timeStyle: "long", hourCycle: "h24", timeZone: "UTC" }) : "Invalid Date";

            koinly1Transactions = {
                date: date,
                sent_amount: formatNumber(t.leftSideValue),
                sent_currency: t.leftSideLabel ? t.leftSideLabel.split("-")[0] : "Unknown",
                received_amount: formatNumber(t.rightSideValue),
                received_currency: t.rightSideLabel ? t.rightSideLabel.split("_")[0] : "Unknown",
                description: label,
            };
            if (label === "Buy Stablecoin") {
                koinly2Transactions = {
                    date: date,
                    sent_amount: formatNumber(t.rightSideValue),
                    sent_currency: t.rightSideLabel ? t.rightSideLabel.split("_")[0] : "Unknown",
                    txhash: t.payment_id || null,
                    description: "Transfer",
                };
                koinly3Transactions = {
                    date: date,
                    received_amount: formatNumber(t.rightSideValue),
                    received_currency: t.rightSideLabel ? t.rightSideLabel.split("_")[0] : "Unknown",
                    description: "Deposit",
                };
            } else {
                koinly2Transactions = {
                    date: date,
                    received_amount: formatNumber(t.rightSideValue),
                    received_currency: t.rightSideLabel ? t.rightSideLabel.split("_")[0] : "Unknown",
                    txhash: t.payment_id || null,
                    description: "Deposit",
                };
                koinly3Transactions = {
                    date: date,
                    sent_amount: formatNumber(t.rightSideValue),
                    sent_currency: t.rightSideLabel ? t.rightSideLabel.split("_")[0] : "Unknown",
                    txhash: t.payment_id || null,
                    description: "Withdraw",
                };
            }

            koinlyTransactionsList.push(koinly1Transactions);
            koinlyTransactionsList.push(koinly2Transactions);
            koinlyTransactionsList.push(koinly3Transactions);
        });
    }

    return (
        <CSVLink filename={`${firstName}_paytrie_koinly.csv`} data={koinlyTransactionsList}>
            <button className="px-4 py-2 bg-black text-white dark:bg-white dark:text-black flex items-center">
                Export CSV <DownloadIcon className="w-4 h-4 ml-2" />
            </button>
        </CSVLink>
    );
};
