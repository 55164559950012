import { MouseEventHandler } from 'react';
import { useBuySellStore } from '../../../reducers/transactionReducer';
import { BackButton } from '../../../components/widget-components';

export const UseEthInfo = ({ onHide }: { onHide: MouseEventHandler }) => {
    const [quoteEngine, network] = [
        useBuySellStore.use.quoteEngine(),
        useBuySellStore.use.network(),
      ]
      const gasFees = quoteEngine?.getGasFee(network) || 0;
      const ethAmount = (gasFees * 2);
    
    return (
        <div className="top-0 bottom-0 left-0 right-0 absolute dark:bg-stone-900 bg-cloud overflow-y-scroll p-5 z-10">
            <BackButton aria-label="Go back" onClick={onHide}>
            &#x27f5;
            </BackButton>
            <div>
                <div className="pl-[15px] text-[15px] leading-none">
                    <p>For an additional ${ethAmount.toFixed(2)}, PayTrie will send ${gasFees.toFixed(2)} worth of ETH with this transaction.</p>
                    <p>This is highly recommended for new users or newly generated wallets.</p>
                    <p>This is an optional feature and is not required if you are sending to an exchange address</p>
                </div>
            </div>
        </div>
    );
};