import { useState } from 'react';
import { isAxiosError } from 'axios';
import { ImageData } from 'src/requests/signup';
import { uploadFile } from 'src/requests/upload';
import { UploadFileComp } from 'src/panels/signup/ManualCheck/UploadFile';
import {   PoweredByLabel, PaytrieTopIcon
} from '../../components/widget-components';
import { WidgetLayout } from '../Buy/WidgetLayout';
import { Button } from 'src/components/Button/Button';

const Upload = () => {

    const [error, setError] = useState('');
    const [fileUploadSuccess, setFileUploadSuccess] = useState(false);
    const [files, setFiles] = useState<ImageData[]>([]);
    const isInIframe = window.self !== window.top;

    const upload = async (email: string, fileData: ImageData) => {

        const newFilesList = [...files, fileData];

        const { response, error } = await uploadFile(
          email,
          newFilesList,
        );
        if (response) {
          //console.log("RESPONSE:", response.data.status);
          setFileUploadSuccess(true);
        }
        if (error && isAxiosError(error)) {
          setError("Unable to upload document. Please contact paytrie support at support@paytrie.com.");
        }
    };


    if (fileUploadSuccess) {
      return (
        <WidgetLayout showInteracLabel={false} showPoweredByLabel={false}>
          <PaytrieTopIcon className="absolute top-8 right-5" />
          <div className="my-auto flex flex-col justify-center" style={{ paddingBottom: "400px" }}>
            <p className='text-center mt-40 mb-10'>Your document was uploaded successfully.</p>
            <Button
                onClick={() => window.location.href = '/upload'}
                className="mt-4"
              >
                Upload Another Document
            </Button>
          </div>
          <PoweredByLabel /> 
        </WidgetLayout>
      )
    }

    return (
      <WidgetLayout showInteracLabel={false} showPoweredByLabel={true}>
      <PaytrieTopIcon className="absolute top-8 right-5" />
      <div className="flex items-center justify-center min-h-screen     overflow-hidden">
        <div className="w-full max-w-lg dark:text-white py-0 px-4 sm:px-6 lg:px-8 " style={{ position: 'absolute', top: !isInIframe ? '9%' : '2%', width: '100%' }}>
          {/* <div className="flex items-center justify-center mb-8">
            <img src={PaytrieFullIcon} alt="PayTrie Logo" className="dark:invert" style={{ width: '200px', height: 'auto' }} />
          </div> */}
          <div className="text-lg font-bold uppercase" style={{paddingTop: '20px', paddingBottom: '30px'}}>
                SUPPLEMENTARY DOCUMENTATION UPLOAD
          </div>
          <div className="space-y-4"></div>
          <div className="mb-12">
                
                <UploadFileComp
                onConfirm={(fileData, email) => {
                  upload(email, fileData);
                }}
                />
                {error && <div className="text-rose-600">{error}</div>}
                {/* <div style={{paddingTop: '40px'}}>
                  <PoweredByLabel /> 
                </div> */}
            </div>  
            </div>  
         </div>    
        </WidgetLayout>
    );
};

export default Upload;