import { Button, Toast } from '@douyinfe/semi-ui';

const Semitest = () => {

    return (

        <div className="flex items-center justify-center min-h-screen bg-white dark:bg-pebble">
            <Button className="" onClick={() => Toast.warning({ content: 'welcome' })}><span className="text-white">Hello Semi</span></Button>
        </div>
    );
};

export default Semitest;