import { usePageStateStore, useProfileStore } from '../../../reducers';
import { getLoggedInUser } from '../../../requests/user';
import { useEffect, useState } from 'react';
import { PersonalInfo } from './PersonalInfo';
import { ReferralInfo } from './ReferralInfo';
import { LoadingIcon } from '../../../components/LoadingIcon';
import { useNavigate } from 'react-router-dom';

const toTitleCase = (str: string) =>
  str.replace(/\b\w/g, (char) => char.toUpperCase());

const toUpperCase = (str: string) => str.toUpperCase();

const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const Profile = () => {
  const {
    setFirstName,
    setLastName,
    setEmail,
    setCity,
    setProvince,
    setPhoneNumber,
    setPostalCode,
    setBirthDay,
    setBirthMonth,
    setBirthYear,
    setAddress1,
    setAddress2,
    setInteracETransferQuestion,
    setInteracETransferAnswer,
    setReferralCode,
    setReferralCredits,
    setReferralLink,
  } = useProfileStore();
  const [isLoading, setIsLoading] = useState(false);
  const authToken = usePageStateStore.use.authToken() || '';
  const navigate = useNavigate();

  useEffect(() => {

    async function fetchUser() {
      setIsLoading(true);
      const { response, error } = await getLoggedInUser(authToken);

      if (response) {
        const user = response.data ?? {};
        setAddress1(toTitleCase(user.address1 || ''));
        setAddress2(toTitleCase(user.address2 || ''));
        setCity(capitalizeFirstLetter(user.city || ''));
        setProvince(toUpperCase(user.province || ''));
        setPostalCode(toUpperCase(user.postal || ''));
        setFirstName(toTitleCase(user.first_name || ''));
        setLastName(toTitleCase(user.last_name || ''));
        setEmail(user.email || '');
        setPhoneNumber(user.phone || '');
        setInteracETransferQuestion('Paytrie');
        setInteracETransferAnswer(user.interac || '');
        setReferralCode(user.referral_code || '');
        setReferralLink(`https://paytrie.com/?ref=${user.referral_code}`);
        setReferralCredits(Number(user.referral_credits || 0));

        if (user.dob) {
          const [day, month, year] = user.dob.split('-');
          setBirthDay(day);
          setBirthMonth(month);
          setBirthYear(year);
        }
      } else if (error) {
        // Should set error
        navigate('/EasyLogin');
      }

      setIsLoading(false);
    }

    fetchUser();
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-cloud dark:bg-pebble">
        <LoadingIcon />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-start min-h-screen bg-cloud dark:bg-pebble dark:text-white px-4 py-0">
      <div className="flex flex-col lg:flex-row gap-8 w-full">
        <div className="w-full lg:w-2/3 bg-cloud dark:bg-pebble p-6 rounded-lg dark:shadow-white">
          <h2 className="text-xl font-semibold text-left text-gray-800 dark:text-gray-100 mb-4 border-b border-gray-900 dark:border-gray-600">
            User Information
          </h2>
          <div className="text-left space-y-4">
            <PersonalInfo />
          </div>
        </div>
        <div className="w-full lg:w-2/3 bg-cloud dark:bg-pebble p-6 rounded-lg dark:shadow-white">
          <h2 className="text-xl font-semibold text-left text-gray-800 dark:text-gray-100 mb-4 border-b border-gray-900 dark:border-gray-600">
            Referral Details
          </h2>
          <div className="text-left space-y-4">
            <ReferralInfo />
          </div>
        </div>
      </div>
    </div>
  );
  
};

export default Profile;
