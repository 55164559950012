import { AxiosError, AxiosResponse, isAxiosError } from 'axios';

import axios from './axios';

export type ImageData = {
    name: string;
    type: string;
    url: string;
  };

export async function uploadFile(
    email: string,
    files: ImageData[],
) {
    try {
        const response = await axios.post<{
            success: boolean;
        }>(
            'upload',
            {
                email,
                fileList: files
            }
        );
        return { response };
    } catch (e) {
        console.log("EROR:", e);
        return { error: e };
    }
}