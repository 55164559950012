import { styled } from 'styled-components';
import { useBuySellStore, TransactionType } from '../../reducers/transactionReducer';
import { StableCoinValue, StableCoins, Networks } from '../../utils/currencies';
import { ReactNode } from 'react';

const List = styled.ol`
  display: flex;
  flex-direction: column;
`;

const Entry = styled.li`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  padding-left: 15px;
  position: relative;
  padding-bottom: 10px;
  &:before {
    content: '';
    position: absolute;
    right: 100%;
    top: 2px;
    height: calc(100% - 22px);
    border-right: 2px dotted;
    border-color: var(--rock);
    top: 23px;
  }
  &:after {
    content: '';
    position: absolute;
    left: calc(0px - 11px);
    top: 2px;
    width: 20px;
    height: 20px;
    border: 2px solid;
    border-color: var(--rock);
    border-radius: 50%;
    background: #B7B6B6;
  }
  &:last-child {
    &:before {
      display: none;
    }
  }
  &:first-child:after {
    background: #B7B6B6;
  }
`;

const ConversionRow = ({ currency }: { currency: StableCoinValue }) => {
  const quoteEngine = useBuySellStore.use.quoteEngine();
  const network = useBuySellStore.use.network();
  return (
    <div>
      <span className="font-normal leading-3">
        1 {StableCoins[currency as keyof typeof StableCoins]}-{network}&nbsp;=&nbsp;
      </span>
      <span className="text-clementine dark:text-sun">
        {quoteEngine?.format(1, currency, 4)} CAD
      </span>
    </div>
  );
};

const Title = ({ children }: { children: ReactNode }) => {
  return <div className="text-clementine dark:text-sun">{children}</div>;
};

const SwapTitle = ({ children }: { children: ReactNode }) => {
  return <div className="text-black dark:text-sun">{children}</div>;
};

const SubTitle = ({ children }: { children: ReactNode }) => {
  return <div className="text-xs dark:text-rock">{children}</div>;
};

export const SubTotal = () => {
  const [stablecoin, amount, quoteEngine, network, transactionType] = [
    useBuySellStore.use.stableCoin(),
    useBuySellStore.use.fiatAmount(),
    useBuySellStore.use.quoteEngine(),
    useBuySellStore.use.network(),
    useBuySellStore.use.transactionType()
  ];

  return (
    <List className="my-auto w-full">
      <Entry className="after:bg-cloud after:dark:bg-pebble">
        <div>
          <ConversionRow currency={stablecoin} />
          {/* <SubTitle>Estimated conversion rate</SubTitle> */}
        </div>
      </Entry>
      {/* <Entry>
        <div>
          <Title>${quoteEngine?.getPaytrieFee(amount, stablecoin).toFixed(2)}</Title>
          <SubTitle>Swap fee (0.{stablecoin === StableCoins.USDC ? 6: '05'}%, min ${(stablecoin === StableCoins.CADC)? 2: 5})</SubTitle>
        </div>
      </Entry> */}
      {(transactionType === TransactionType.BUY && network === Networks.ethereum) &&
      <Entry>
        <Title>${quoteEngine?.getGasFee(network)}</Title>
        <SubTitle>
          Gas fee (gas price: {quoteEngine?.getGasPrice().toFixed(0)} gwei)
        </SubTitle>
      </Entry>
      }
      <Entry>
        <SwapTitle>Swap fee: 0.{stablecoin === StableCoins.USDC ? 6: '05'}% (min ${(stablecoin === StableCoins.CADC)? 2: 5})</SwapTitle>
      </Entry>
    </List>
  );
};
