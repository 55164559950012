import { useEffect, useState } from 'react';
import { generateVerificationLink, updateUserCurrentFlow } from '../../requests/signup';
import Iframe from 'react-iframe'
import { useSignupStore } from '../../reducers';
import { useNavigate } from 'react-router-dom';

export default function OliuIFrame() {
    const navigate = useNavigate();
    const [urlLoaded, setUrlLoaded] = useState<boolean>(false);
    const [redirectUrl, setRedirectUrl] = useState('');
    const [authToken, setAuthToken] = useState('');
    const [addedWindowListener, setAddedWindowListener] = useState<boolean>(false);
    const store = useSignupStore();
    const [setSessionId] = [
      useSignupStore.use.setSessionId(),
    ];
    let [email] = [
      useSignupStore.use.email(),
    ];
    if (email.length === 0) {
      console.log("READ EMAIL FROM LOCAL STORAGE");
      email = localStorage.getItem('email') || '';
    }
    const oliuVerifyType = useSignupStore.use.oliuVerifyType();

    const getOliuLink = () => {
        generateVerificationLink(email, oliuVerifyType)
          .then((resp) => {
            if (resp) {
              let redirUrl: string = resp.url || '';
              setUrlLoaded(true);
              setRedirectUrl(redirUrl);
            }
          })
      };
    
      useEffect(() => {
        const errorRedirect = import.meta.env.VITE_SIGNUP_KYC_ERR_REDIRECT;
        if (email.length > 0) {
          getOliuLink();
          updateUserCurrentFlow(email, 'oliu_flow');
        } else {
          navigate(errorRedirect);
        }

        if (!addedWindowListener) {
          window.addEventListener('message', ev => {
            console.log("LOADED WINDOW EVENT LISTENER");
            setAddedWindowListener(true);
          })

          window.onmessage = function (event) {
            if (event.data) {
              if (event.data.startsWith("redirectURL")) {
                console.log("FOUND REDIRECT LINK: ", event.data.split(" ")[1]);
                window.location.replace(event.data.split(" ")[1])
              }
            }
          }
        }
      }, []);

      return (
        <div>
            <Iframe url={redirectUrl}
                    styles={{width: "100vw", height: "100vh"}}
                    id=""
                    className=""
                    display="block"
                    allow="camera; accelometer; fullscreen;"
                    target="_top"
                    position="relative"/>
        </div>
      );
}