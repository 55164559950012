// When buying I need to set the wallet to send the stable coin to.

import { useState } from 'react';
import { WidgetLayout } from '../WidgetLayout';
import { AddWallet } from './AddWallet';
import { TransactionInstructions } from './TransactionInstructions';
import {
  LogoutButton,
  PaytrieTopIcon,
} from '../../../components/widget-components';
import { usePageStateStore } from '../../../reducers';
import { useNavigate } from 'react-router-dom';
import { DarkModeMenu } from 'src/panels/signup/DarkmodeSignUpPanel';

enum BuyPageState {
  ADD_WALLET = 'ADD_WALLET',
  SHOW_SUMMARY = 'SHOW SUMMARY',
}

export const Buy = () => {
  const navigate = useNavigate();
  const [wallet, setWallet] = useState('');
  const [buyState, setBuyState] = useState(BuyPageState.ADD_WALLET);
  const [loading, setLoading] = useState(false); 
  const [tx_id, setTxId] = [
    usePageStateStore.use.tx_id(),
    usePageStateStore.use.setTxId(),
  ]

  const [authToken, clearAuthToken] = [
    usePageStateStore.use.authToken(),
    usePageStateStore.use.clearAuthToken(),
  ];

  const confirmWallet = (txnId: string) => {
    setTxId(txnId);
    setBuyState(BuyPageState.SHOW_SUMMARY);
    navigate("/confirmation")
  };

  return (
    <WidgetLayout showInteracLabel={false} showPoweredByLabel={true}>
      <div className='mt-14'>
      {!loading && authToken != null ? (
        <div className="absolute top-10 right-0">
        <DarkModeMenu showSignOutButton={true} />
        </div>
      ) : (
        <div className="flex justify-end items-center mt-3">
          <PaytrieTopIcon />
        </div>
      )}
      
      {buyState === BuyPageState.ADD_WALLET && (
        <AddWallet
          wallet={wallet}
          onSetWallet={setWallet}
          onSubmitSuccess={confirmWallet}
          setLoading={setLoading} 
        />
      )}
      {buyState === BuyPageState.SHOW_SUMMARY && (
        <TransactionInstructions orderId={tx_id} wallet={wallet} />
      )}
      </div>
    </WidgetLayout>
  );
};
