import {
  TransactionType,
  useBuySellStore,
} from '../../../reducers/transactionReducer';
import { PaymentTypes } from '../../../utils/currencies';
import { InteracBuyInstructions } from './BuyInstructions';
import { InteracRequestInstructions } from './InteracRequestInstructions';
import { SellTransactionInstructions } from './SellTransactionInstructions';

type TransactionInstructionsProps = {
  orderId: string;
  wallet: string;
};

export const TransactionInstructions = ({
  orderId,
  wallet,
}: TransactionInstructionsProps) => {
  const transactionType = useBuySellStore.use.transactionType();
  const requestType = useBuySellStore.use.requestType();

  if (transactionType === TransactionType.SELL) {
    return (
      <SellTransactionInstructions sourceWallet={wallet} orderId={orderId} />
    );
  }
  if (requestType === PaymentTypes.REQUEST) {
    return <InteracRequestInstructions wallet={wallet} orderId={orderId} />;
  }
  return <InteracBuyInstructions wallet={wallet} orderId={orderId} />;
};
