import { NetworkValue, StableCoinValue } from '../utils/currencies';
import axios from './axios';

const endpoints = {
  transact: '/transactions',
};

export type TransactionResponse = {
  tx: string;
  fxRate: number;
};

type BuyPayload = {
  quoteId: number;
  gasId?: number;
  email: string;
  wallet: string;
  amount: number;
  stableCoin: StableCoinValue;
  network: NetworkValue;
  ethCost?: number; 
  useReferral: boolean; 
  token: string;
};

export const submitBuy = async (buyPayload: BuyPayload) => {
  try {
    const response = await axios.post<TransactionResponse>(
      endpoints.transact,
      {
        wallet: buyPayload.wallet,
        email: buyPayload.email,
        quoteId: buyPayload.quoteId,
        gasId: buyPayload.gasId ?? 0,
        leftSideLabel: 'CAD',
        leftSideValue: buyPayload.amount,
        rightSideLabel: `${buyPayload.stableCoin}-${buyPayload.network}`,
        useReferral: buyPayload.useReferral,
        ...((import.meta.env.VITE_USE_VENDOR_CODE === 'TRUE') && {vendorId: import.meta.env.VITE_ENV_VENDOR_CODE}),
        ethCost: buyPayload.ethCost,
      },
      {
        headers: { Authorization: `bearer ${buyPayload.token}` },
      }
    );
    console.log("SUBMIT BUY RESPONSE:", response);
    return { response };
  } catch (error) {
    return { error };
  }
};

type SellPayload = {
  stableCoin: StableCoinValue;
  amount: number;
  wallet: string;
  email: string;
  quoteId: number;
  gasId?: number;
  network: NetworkValue;
  useReferral: boolean;
  token: string;
};

export const submitSell = async (payload: SellPayload) => {
  try {
    const response = await axios.post<TransactionResponse>(
      endpoints.transact,
      {
        quoteId: payload.quoteId,
        gasId: payload.gasId ?? 0, // TODO: Not sure where this comes from
        email: payload.email,
        wallet: payload.wallet,
        leftSideLabel: `${payload.stableCoin}-${payload.network}`,
        leftSideValue: payload.amount,
        rightSideLabel: 'CAD',
        ...((import.meta.env.VITE_USE_VENDOR_CODE === 'TRUE') && {vendorId: import.meta.env.VITE_ENV_VENDOR_CODE}),
        useReferral: payload.useReferral,
      },
      {
        headers: { Authorization: `bearer ${payload.token}` }
      }
    );
    console.log("SUBMIT SELL RESPONSE:", response);
    return { response };
  } catch (error) {
    return { error };
  }
};
