import { useState, useEffect } from 'react';
import { PanelLayout } from "./PanelLayout";
import {
    verifyEmail
  } from '../../requests/login';

export default function LoginVerifyPanel() {

    const queryParameters = new URLSearchParams(window.location.search)
    const email = queryParameters.get("email") || '';
    const id = queryParameters.get("id") || '';
    const [status, setStatus] = useState('');
    const [title, setTitle] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        async function callVerifyEmail(email: string, id: string) {
            const { response, errorMessage } = await verifyEmail(email, id);

            if (response && response.data.status == 'success') {
                setSuccess(true);
                setTitle('SUCCESSFUL');
                setStatus("You may now close this window.");
            } else if (errorMessage && ( errorMessage === 'This link has expired' || errorMessage === 'Link expired. Link is only valid for 5 minutes' || errorMessage === 'Already verified')) {
                setSuccess(false);
                setTitle('SIGN IN LINK EXPIRED')
                setStatus("Please try again.")
            } else {
                setSuccess(false);
                setTitle('')
                setStatus("Something went wrong during sign-in. Please try again or contact support@paytrie.com if the issue persists.")
            }
        }
        // Call function to secureVerify
        callVerifyEmail(email, id);
    }, []);

    return (
        <PanelLayout>
            <div className="flex flex-col gap-5">
                <h2 className="text-base font-bold uppercase">{title}</h2>

                <div className="text-base">{status}</div>
            </div>    
        </PanelLayout>
    )
}