import axios from "./axios";

export type UserResponse = {
  user_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  dob: string;
  address1: string;
  address2: string;
  city: string;
  province: string;
  occupation: string;
  wallet: string;
  verified_2FA: string;
  verified_email: string;
  verified_phone: string;
  status: string;
  interact: string;
  linked_wallet: string;
  linked_coins: string;
  linked_on: string;
  referral_credits: number;
  referral_code: string;
  daily_limit: string;
  atb: boolean;
  hibp: boolean;
  postal: string;
  interac: string;
};

export type TransactionResponse = {
  tx_id: string;
  user_id: string;
  leftSideLabel: string;
  leftSideValue: string;
  rightSideLabel: string;
  coin: string;
  status: string;
};

// export const getLoggedInUser = async (token: string) => {
//   return axios.get<UserResponse>('/getUserByToken', {
//     headers: { Authorization: `bearer ${token}` },
//   });
// };

export const getLoggedInUser = async (token: string | null) => {
  try {
    const response = await axios.get<UserResponse>("/getUserByToken", {
      headers: { Authorization: `bearer ${token}` },
    });
    return { response };
  } catch (error) {
    return { error };
  }
};

export const getTransactionsByUser = (email: string, token: string) => {
  return axios.get<TransactionResponse>("/getTransactionsByUser", {
    params: { email },
    headers: { Authorization: `bearer ${token}` },
  });
};
