import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

import { ProtectedRoute } from "./components/ProtectedRoute";
import { BuySell } from "./pages/Buy";
import { Buy } from "./pages/Buy/Transact/Buy";
import { DashboardHistory } from "./pages/Dashboard/DashboardHistory";
import { DashboardProfile } from "./pages/Dashboard/DashboardProfile";
import { DashboardTrade } from "./pages/Dashboard/DashboardTrade";
import Confirmation from "./pages/Orders/Confirmation";
import OrderStatus from "./pages/Orders/OrderStatus";
import Semitest from "./pages/SemiDesignTest/SemiTest";
import { Signup } from "./pages/Signup";
import Upload from "./pages/Upload/Upload";
import EmailLoginPanel from "./panels/login/EmailLoginPanel";
import KeyLoginPanel from "./panels/login/KeyLoginPanel";
import PasswordLoginPanel from "./panels/login/PasswordLoginPanel";
import WelcomePanel from "./panels/login/WelcomePanel";
import LoginVerifyPanel from "./panels/login/LoginVerifyPanel";
import { CompleteRegistration } from "./panels/signup/CompleteRegistration";
import { CompleteRegistrationStandard } from "./panels/signup/CompleteRegistrationStandard";
import OliuIframe from "./panels/signup/OliuIFrame";
import { RegistrationFailure } from "./panels/signup/RegistrationFailure";
import OliuRedirectFailed from "./panels/signup/OliuRedirectFailed";
import OliuRedirectSuccess from "./panels/signup/OliuRedirectSuccess";
import PaytrieWidget from "./PaytrieWidget";

const router = createBrowserRouter([
  {
    path: "/",
    element: <BuySell />,
  },
  // {
  //   path: "/Welcome",
  //   element: <WelcomePanel />
  // },
  {
    path: "/Signup",
    element: <Signup />,
  },
  {
    path: "/SignupEmail",
    element: <Signup />,
  },
  {
    path: "/SignupVerifyEmail",
    element: <Signup />,
  },
  {
    path: "/SignupPhone",
    element: <Signup />,
  },
  {
    path: "/SignupVerifySMS",
    element: <Signup />,
  },
  {
    path: "/SignupDisclosure",
    element: <Signup />,
  },
  {
    path: "/SignupUserInfo",
    element: <Signup />,
  },
  {
    path: "/SignupOccupation",
    element: <Signup />,
  },
  {
    path: "/SignupKYC",
    element: <Signup />,
  },
  {
    path: "/SignupPassword",
    element: <Signup />,
  },
  {
    path: "/EasyLogin",
    element: <EmailLoginPanel landing={false} />,
  },
  {
    path: "/LandingLogin",
    element: <EmailLoginPanel landing={true} />,
  },
  {
    path: "/Login",
    element: <Navigate to="/LandingLogin" replace />,
  },
  {
    path: "/KeyLogin",
    element: <KeyLoginPanel />,
  },
  {
    path: "/LoginVerify",
    element: <LoginVerifyPanel />,
  },
  {
    path: "/OliuIframe",
    element: <OliuIframe />,
  },
  {
    path: "/OliuComplete",
    element: <CompleteRegistration />,
  },
  {
    path: "/OliuComplete24",
    element: <CompleteRegistrationStandard />,
  },
  {
    path: "/Failed",
    element: <RegistrationFailure />,
  },
  {
    path: "/RedirectSuccess",
    element: <OliuRedirectSuccess />,
  },
  {
    path: "/RedirectFailed",
    element: <OliuRedirectFailed />,
  },
  {
    path: "/Status",
    element: <OrderStatus />,
  },
  {
    path: "/Confirmation",
    element: <Confirmation />,
  },
  {
    path: "/Upload",
    element: <Upload />,
  },
  {
    path: "/Semitest",
    element: <Semitest />,
  },
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: "/Transactions",
        element: <Buy />,
      },
      {
        path: "/Dashboard",
        element: <DashboardTrade />,
      },
      {
        path: "/History",
        element: <DashboardHistory />,
      },
      {
        path: "/Profile",
        element: <DashboardProfile />,
      },
    ],
  },
  {
    path: "*",
    element: <BuySell />,
  },
]);

const App = () => {
  return (
    <div className="w-full md:flex md:justify-center md:items-center">
      <div className="lg:max-w-[730px] lg:w-[730px]">
        {/* <PaytrieWidget /> */}
        <RouterProvider router={router} />
      </div>
    </div>
  );
};

export default App;
