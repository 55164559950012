import axios from './axios';

const userEndpoints = {
  GET_ORDER_STATUS: 'getTransactionById',
  CANCEL_TRANSACTION: 'userCancelTransaction',
};

export type OrderStatusResponse = {
  date: string;
  tx_id: string;
  wallet: string;
  status: string;
  leftSideValue: string;
  leftSideLabel: string;
  rightSideValue: string;
  rightSideLabel: string;
  atb_status: string;
  payment_id: string;
  eth_fee: boolean;
  payment_type: string;
  coin: string;
  fee: number;
};

const getAuthToken = () => {
  return localStorage.getItem('authToken');
};

export const getOrderStatusByTransactionId = (transactionId: string) => {
  return axios.get<OrderStatusResponse>(`/${userEndpoints.GET_ORDER_STATUS}`, {
    params: { tx_id: transactionId },
  });
};

export const userCancelTransaction = (transactionId: string) => {
  const token = getAuthToken();
  return axios.post(`/${userEndpoints.CANCEL_TRANSACTION}`, null, {
    params: { tx_id: transactionId },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
