import { Button } from '../../../components/Button/Button';
import { Pages, usePageStateStore } from '../../../reducers';
import { useNavigate } from 'react-router-dom';

type TransactionInstructionsProps = {
  orderId: string;
  wallet: string;
};

export const InteracBuyInstructions = ({
  orderId,
  wallet,
}: TransactionInstructionsProps) => {
  const goToPage = usePageStateStore.use.goToPage();
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-5">
      <div className="grid grid-cols-2 md:grid-cols-3 mb-3 flex-grow">
        <div className="col-span-2 lg:col-span-3">
          <h1 className="uppercase text-2xl">Next Steps</h1>
          <p className="mb-3">
            Please send your interac e-Transfer using the following instructions
          </p>
        </div>
        <h4>Email address</h4>
        <h4>Security Questions</h4>
        <h4>Security Answer</h4>
        <div>funding@paytrie.com</div>
        <div>fayesaytang</div>
        <div>862AJ388GR</div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
        <div className="col-span-2 md:col-span-3 uppercase font-bold">
          Order summary
        </div>
        <div>
          <h4>Order ID</h4>
          <div>{orderId}</div>
        </div>
        <div>
          <h4>Adding to</h4>
          <div>{wallet}</div>
        </div>
        <div>
          <h4>Receive via</h4>
          <div>Interac</div>
        </div>
      </div>
      {/* <Button onClick={() => goToPage(Pages.CALC)}>Start over</Button> */}
      <Button
        onClick={() => {
          navigate('/');
        }}
      >
        Start over
      </Button>
    </div>
  );
};
