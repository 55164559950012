import { createContext, useContext } from "react";

type AuthContextType = {
  loading: boolean;
  isAuth: boolean;
  authError: boolean;
};

const initialState: AuthContextType = {
  loading: true,
  isAuth: false,
  authError: false,
};

export const AuthContext = createContext<AuthContextType>(initialState);

export const useAuth = () => {
  return useContext(AuthContext);
};
