import axios from './axios';

export type QuoteResponse = {
  timestamp: string;
  id: number;
  fee: number;
  gasFee: number;
  gasId: number;
  gasPrice: number;
  cadusd: number;
  usddai: number;
  caddai: number;
  usdbusd: number;
  usdusdc: number;
  usdusdt: number;
};

export const getQuote = () => {
  return axios.get<QuoteResponse>('/quotes');
};
