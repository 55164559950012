import { ReactNode, useEffect, useState } from 'react';
import {
  InteracLabel,
  PaytrieTopIcon,
  PoweredByLabel,
} from '../../components/widget-components';
import ArrowPattern from '../../assets/Paytrie_Patterns_arrow_snow_2.png';
import { styled } from 'styled-components';
import PanelBackgroundImage from '../../assets/Paytrie_Patterns_arrow_snow.png';

const WidgetBackground = styled.div`
  position: relative;
  &:after {
    background-image: url(${PanelBackgroundImage});
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: center;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100px;
    width: 100%;
    z-index: -1;
  }
`;

interface FooterProps {
  showInteracLabel: boolean;
  showPoweredByLabel: boolean; 
}

const Footer = ({ showInteracLabel, showPoweredByLabel }: FooterProps) => {
  return (
    <div className="absolute bottom-0 left-0 right-0 flex flex-col items-center justify-center pb-4">
      {showInteracLabel && (
        <div className="mb-2">
          <InteracLabel />
        </div>
      )}
      {showPoweredByLabel && (
        <div>
          <PoweredByLabel />
        </div>
      )}
    </div>
  );
};

interface WidgetLayoutProps {
  children: ReactNode;
  showInteracLabel?: boolean;
  showPoweredByLabel?: boolean;
}

export const WidgetLayout = ({ 
  children, 
  showInteracLabel = true, 
  showPoweredByLabel = true 
}: WidgetLayoutProps) => {
  const getInitialMode = () => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode ? savedMode === 'true' : window.matchMedia('(prefers-color-scheme: dark)').matches;
  };

  const [darkMode, setDarkMode] = useState(getInitialMode);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }

    const handleMessageFromParent = (event: { data: { darkMode: boolean; }; }) => {
      if (event.data && typeof event.data.darkMode === 'boolean') {
        setDarkMode(event.data.darkMode);
        localStorage.setItem('darkMode', String(event.data.darkMode));
      }
    };

    window.addEventListener('message', handleMessageFromParent);
    return () => window.removeEventListener('message', handleMessageFromParent);
  }, [darkMode]);

  useEffect(() => {
    const syncDarkModeAcrossTabs = (event: StorageEvent) => {
      if (event.key === 'darkMode') {
        setDarkMode(event.newValue === 'true');
      }
    };
    window.addEventListener('storage', syncDarkModeAcrossTabs);
    return () => window.removeEventListener('storage', syncDarkModeAcrossTabs);
  }, []);

  return (
    <WidgetBackground className="after:invert dark:after:invert-0">
      <div className="relative flex flex-col gap-5 p-10 h-[700px] overflow-hidden">
        {children}
        <Footer showInteracLabel={showInteracLabel} showPoweredByLabel={showPoweredByLabel} />
      </div>
    </WidgetBackground>
  );
};
