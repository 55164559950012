import EthereumLogo from '../assets/networks/ethereum-eth-logo.png';
import PolygonLogo from '../assets/networks/polygon-matic-logo.png';
import ArbitrumLogo from '../assets/networks/arbitrum-arb-logo.png';
import BinanceLogo from '../assets/networks/bnb-bnb-logo.png';
import OptimismLogo from '../assets/networks/optimism-ethereum-op-logo.png';
import BaseLogo from '../assets/networks/Base_Symbol_Blue.png';
import SXLogo from '../assets/networks/sx-network-logo.png';
import SOLLogo from '../assets/img/icons/cryptoicons/SVG/sol.svg';
import AVAXLogo from '../assets/img/icons/cryptoicons/SVG/avax.svg';
import CELOLogo from '../assets/celo_network.svg';
import CADARB from '../assets/currency/CADC_ARB.png';
import CADETH from '../assets/currency/CADC_ETH.png';
import CADPOLY from '../assets/currency/CADC_POLY.png';
import USDPOLY from '../assets/currency/USDC_POLY.png';
import USDETH from '../assets/currency/USDC_ETH.png';
import USDARB from '../assets/currency/USDC_ARB.png';
import USDCAVAX from '../assets/currency/USDC_AVAX.png';
import USDCCELO from '../assets/currency/USDC_CELO.png';
import USDCBN from '../assets/currency/USDC_BSC.png';
import USDCBASE from '../assets/currency/USDC_BASE.png';
import USDCOPTI from '../assets/currency/USDC_OP.png';
import USDCSX from '../assets/currency/USDC_SX.png';
import CADC from '../assets/currency/CADC2.svg';
import USDC from '../assets/img/icons/cryptoicons/SVG/usdc.svg';
import USDCSOL from '../assets/currency/USDC_SOL.png';
import CAD from '../assets/currency/cad.png'

type NetworkOption = {
  name: string;
  icon: string;
};

export const Networks = {
  ethereum: 'ETH',
  polygon: 'POLY',
  arbitrum: 'ARB',
  binance: 'BSC',
  optimism: 'OP',
  sx: 'SX',
  sol: 'SOL',
  base: 'BASE',
  avalanche: 'AVAX', 
  celo: 'CELO',
} as const;

export type NetworkValue = (typeof Networks)[keyof typeof Networks];

export const NetworkOptions: Record<NetworkValue, NetworkOption> = {
  [Networks.ethereum]: {
    name: 'Ethereum',
    icon: EthereumLogo,
  },
  [Networks.polygon]: {
    name: 'Polygon',
    icon: PolygonLogo,
  },
  [Networks.arbitrum]: {
    name: 'Arbitrum',
    icon: ArbitrumLogo,
  },
  [Networks.binance]: {
    name: 'BSC',
    icon: BinanceLogo,
  },
  [Networks.optimism]: {
    name: 'Optimism',
    icon: OptimismLogo,
  },
  [Networks.sx]: {
    name: 'SX',
    icon: SXLogo,
  },
  [Networks.sol]: {
    name: 'Solana',
    icon: SOLLogo,
  },  
  [Networks.base]: {
    name: 'Base',
    icon: BaseLogo,
  },
  [Networks.avalanche]: {
    name: 'Avalanche',
    icon: AVAXLogo,
  },
  [Networks.celo]: {
    name: 'Celo',
    icon: CELOLogo,
  },
};

export function getNetwork(abr: string) {
  switch (abr) {
    case 'ETH':
      return Networks.ethereum;
    case 'POLY':
      return Networks.polygon;
    case 'ARB':
      return Networks.arbitrum;
    case 'BSC':
      return Networks.binance;
    case 'OP':
      return Networks.optimism;
    case 'SX':
      return Networks.sx;
    case 'SOL':
      return Networks.sol;
    case 'BASE':
      return Networks.base;
    case 'AVAX':
      return Networks.avalanche;  
    case 'CELO':
      return Networks.celo;  
    default:
      return Networks.ethereum;  
  }
}

export const StableCoins = {
  CADC: 'CADC',
  USDC: 'USDC',
  // USDT: 'USDT',
  // DAI: 'DAI',
  // BUSD: 'BUSD',
} as const;

export type StableCoinValue = (typeof StableCoins)[keyof typeof StableCoins];

export const isStableCoin = (x: any): x is StableCoinValue => Object.values(StableCoins).includes(x);

export function getStableCoinType(abr: string) {
  switch(abr) {
    case 'USDC':
      return StableCoins.USDC;
    case 'CADC':
      return StableCoins.CADC;  
    default:
      return StableCoins.USDC;  
  }
}

export const supportedCurrencies: Record<NetworkValue, StableCoinValue[]> = {
  [Networks.ethereum]: [StableCoins.CADC, StableCoins.USDC],
  [Networks.arbitrum]: [StableCoins.CADC, StableCoins.USDC],
  [Networks.polygon]: [StableCoins.CADC, StableCoins.USDC],
  [Networks.optimism]: [StableCoins.USDC],
  [Networks.sx]: [StableCoins.USDC],
  [Networks.sol]: [StableCoins.USDC],
  [Networks.binance]: [StableCoins.USDC],
  [Networks.base]: [StableCoins.USDC],
  [Networks.avalanche]: [StableCoins.USDC],
  [Networks.celo]: [StableCoins.USDC],
};

export const FiatCurrency = {
  CAD: 'CAD',
  USD: 'USD',
};

export const isFiat = (x: any) => Object.values(FiatCurrency).includes(x);

export const PaymentTypes = {
  REQUEST: 'request',
  ETRANSFER: 'e-transfer',
} as const;

export type PaymentType = (typeof PaymentTypes)[keyof typeof PaymentTypes];

export interface CoinOption {
  value: string;
  name: string;
  description: string;
  shortDescription?: string;
  image?: string;
}

export interface StableCoinOption extends CoinOption {
  value: StableCoinValue;
}

export const coinOptions: CoinOption[] = [
  {
    value: PaymentTypes.REQUEST,
    name: 'CAD',
    description: 'Interac Request Money Transfer',
    shortDescription: 'Interac',
    image: CAD
  },
  // {
  //   value: PaymentTypes.ETRANSFER,
  //   name: 'CAD',
  //   description: 'Interac e-Transfer',
  // },
];

export const allTokenOptions: StableCoinOption[] = [
  {
    value: StableCoins.CADC,
    name: 'CADC',
    description: '',
    image: CADC,
  },
  {
    value: StableCoins.USDC,
    name: 'USDC',
    description: '',
    image: USDC,
  },
  // {
  //   value: StableCoins.DAI,
  //   name: 'DAI',
  //   description: 'Coin',
  // },
  // {
  //   value: StableCoins.BUSD,
  //   name: 'BUSD',
  //   description: 'Coin',
  // },
  // {
  //   value: StableCoins.USDT,
  //   name: 'USDT',
  //   description: 'Coin',
  // },
];

export const stableCoinLogos = {
  [`${StableCoins.CADC}${Networks.arbitrum}`]: CADARB,
  [`${StableCoins.CADC}${Networks.ethereum}`]: CADETH,
  [`${StableCoins.CADC}${Networks.polygon}`]: CADPOLY,
  [`${StableCoins.USDC}${Networks.polygon}`]: USDPOLY,
  [`${StableCoins.USDC}${Networks.ethereum}`]: USDETH,
  [`${StableCoins.USDC}${Networks.arbitrum}`]: USDARB,
  [`${StableCoins.USDC}${Networks.optimism}`]: USDCOPTI,
  [`${StableCoins.USDC}${Networks.sx}`]: USDCSX,
  [`${StableCoins.USDC}${Networks.sol}`]: USDCSOL,
  [`${StableCoins.USDC}${Networks.binance}`]: USDCBN,
  [`${StableCoins.USDC}${Networks.base}`]: USDCBASE,
  [`${StableCoins.USDC}${Networks.avalanche}`]: USDCAVAX,
  [`${StableCoins.USDC}${Networks.celo}`]: USDCCELO,
};

export const BUY_MAX_LIMIT_AMT : number = parseInt(import.meta.env.VITE_BUY_MAX_LIMIT_AMT);
export const SELL_MAX_LIMIT_AMT : number = parseInt(import.meta.env.VITE_SELL_MAX_LIMIT_AMT);
export const MIN_LIMIT_AMT : number = parseInt(import.meta.env.VITE_MIN_LIMIT_AMT);
export const FEERATE : number = parseFloat(import.meta.env.VITE_FEERATE);
export const FEERATECADC : number = parseFloat(import.meta.env.VITE_FEERATECADC);
export const MINCADCFEE : number = parseInt(import.meta.env.VITE_MINCADCFEE);
export const MINUSDCFEE : number = parseInt(import.meta.env.VITE_MINUSDCFEE);