import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import {
    useBuySellStore,
  } from '../../reducers/transactionReducer';
import { NetworkItems } from '../../components/Select/NetworkItems';
import { Tokens } from '../../components/Select/TokenPicker/Tokens';
import { stableCoinLogos } from '../../utils/currencies';

export const WidgetModal = () => {

    const stableCoin = useBuySellStore.use.stableCoin();
    const setStableCoin = useBuySellStore.use.setStableCoin();
    const [network, setNetwork] = [useBuySellStore.use.network(), useBuySellStore.use.setNetwork()];
  
    const [dialogPage1, setDialogPage1 ] = [useBuySellStore.use.dialogPage1(), useBuySellStore.use.setDialogPage1()]; 

    return (
        <Dialog.Root>
          <Dialog.Trigger asChild>
            <div className="flex-shrink min-w-min flex items-end mb-2">           
              <span style={{pointerEvents: 'none'}}>
                <div className="flex">
                  <div className="mr-1 mt-1 w-15">
                    {/* <img src={(stableCoin === 'USDC')? USDC :CADC} height={20} width={20} /> */}
                    <img src={stableCoinLogos[`${stableCoin}${network}`]} height={40} width={40} />
                  </div>
                  <div className="text-left flex flex-col justify-center">
                    <div className="flex row font-bold">{stableCoin}</div>
                    <div /> 
                    <div className="text-sm text-pebble dark:text-white font-bold">{network}</div>
                  </div>
                </div>
              </span>
              <span style={{ marginLeft: '8px' }}>
                <svg style={{marginBottom: '20px'}} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.13523 6.15803C3.3241 5.95657 3.64052 5.94637 3.84197 6.13523L7.5 9.56464L11.158 6.13523C11.3595 5.94637 11.6759 5.95657 11.8648 6.15803C12.0536 6.35949 12.0434 6.67591 11.842 6.86477L7.84197 10.6148C7.64964 10.7951 7.35036 10.7951 7.15803 10.6148L3.15803 6.86477C2.95657 6.67591 2.94637 6.35949 3.13523 6.15803Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>
              </span>
            </div>
          </Dialog.Trigger>
          <Dialog.Portal>
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-60 z-40" />
            <Dialog.Content 
              className="DialogContent bg-white dark:bg-pebble z-50" 
              onOpenAutoFocus={(e) => e.preventDefault()} 
              style={{
                top: dialogPage1 ? '250px' : '50px', 
                transform: 'translateX(-50%)', 
                overflow: 'auto'}}>
              {/* conditionally render stable coin select */}
              {dialogPage1 && 
                <div>
                <Dialog.Title className="DialogTitle text-black dark:text-white"><h3 style={{textAlign: 'center'}}><b>Select Stablecoin</b></h3></Dialog.Title>
                {/* Coin select should set local state first, only when final form finished then set both value and label */}
                  <Tokens onSelectToken={(val) => {setStableCoin(val);setDialogPage1(false)}}/>
                  {/* <button onClick={() => setDialogPage1(false)}>Next</button> */}
                </div>
              }

              {!dialogPage1 &&
                <div>
                  <NetworkItems onSelectNetwork={(val) => {setNetwork(val); setDialogPage1(true)}} />
                </div>  
              }
              <Dialog.Close asChild>
                <button style={{position: 'absolute', top: '10px', left: '10px'}} aria-label="Close">
                  <Cross2Icon />
                </button>
              </Dialog.Close>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
    )    
}