import { Button } from '../../../components/Button/Button';
import { Pages, usePageStateStore } from '../../../reducers';
import InteracCircle from '../../../assets/interac-circle.png';

type InteracRequestInstructionsProps = {
  orderId: string;
  wallet: string;
};

export const InteracRequestInstructions = ({
  wallet,
  orderId,
}: InteracRequestInstructionsProps) => {
  const goToPage = usePageStateStore.use.goToPage();
  return (
    <div className="flex flex-col gap-5">
      <h1 className="text-2xl">Almost Done...</h1>
      <div className="flex justify-stretch">
        <div className="flex-grow">
          <div>Order ID</div>
          <div>{orderId}</div>
        </div>
        <div className="flex-grow">
          <div>Adding to</div>
          <div>{wallet}</div>
        </div>
      </div>
      <div>
        <div>Receive via</div>
        <div className="flex gap-2 items-start">
          <img src={InteracCircle} width={20} />
          <div>
            <div>Request Money Transfer (RMT)</div>
            <div className="text-xs">Interac</div>
          </div>
        </div>
      </div>
      <Button onClick={() => goToPage(Pages.CALC)}>Start over</Button>
    </div>
  );
};
