import { useEffect, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import { usePageStateStore } from "src/reducers";
import { getLoggedInUser } from "src/requests/user";
import { AuthContext } from "./auth-context";

interface Props {
  children: React.ReactNode;
}
export const AuthProvider = ({ children }: Props) => {
  const { authToken, clearAuthToken } = usePageStateStore();

  const { data, isError, isLoading, isSuccess, failureReason } = useQuery({
    queryFn: () => getLoggedInUser(authToken),
    queryKey: [authToken],
    retry: false, // No retry on auth request
    enabled: !!authToken,
  });

  useEffect(() => {
    if (isError) {
      console.log("AuthProvider error:", failureReason);
    }
  }, [isError, failureReason]);

  const isAuth = useMemo(() => {
    if (isSuccess) {
      // token is valid
      if (data?.response?.status === 200) {
        return true;
      }
      // token is invalid
      else {
        clearAuthToken();
        return false;
      }
    }
    // all other pending/loading states
    else {
      return false;
    }
  }, [clearAuthToken, data, isSuccess]);

  return (
    <AuthContext.Provider
      value={{ loading: isLoading, isAuth, authError: isError }}
    >
      {children}
    </AuthContext.Provider>
  );
};
