import { FormEvent, useState, useEffect } from 'react';
import { IntendedPurposeSelector } from '../../components/Select/IntendedPurposeSelector';
import {
  BackButton,
  ErrorBlockText,
  TextInput,
  LeftAlignSmallText,
  LeftAlignSmallerText,
} from '../../components/widget-components';
import { updateAndVerifyUser, updateUserCurrentFlow } from '../../requests/signup';
import { useSignupStore } from '../../reducers';
import { Button } from '../../components/Button/Button';
import { Checkbox } from '../../components/Checkbox';
import { EmploymentStatusSelector } from '../../components/Select/EmploymentStatusSelector';
import { OccupationSelector } from '../../components/Select/OccupationSelector';
import { LoadingIcon } from '../../components/LoadingIcon';
import { useNavigate } from 'react-router-dom';
import { FormStates } from './FormStateMachine';

export default function SignupOccupation() {
  const navigate = useNavigate();
  const [politicalAttestation, setPoliticalAttestation] = useState(false);
  const [thirdPartyAttestation, setThirdParyAttestation] = useState(false);
  const store = useSignupStore();
  const [occupation, setOccupation] = [
    useSignupStore.use.occupation(),
    useSignupStore.use.setOccupation(),
  ];
  const [employmentStatus, setEmploymentStatus] = [
    useSignupStore.use.employmentStatus(),
    useSignupStore.use.setEmploymentStatus(),
  ];
  const [occupationDetails, setOccupationDetails] = [
    useSignupStore.use.occupationDetails(),
    useSignupStore.use.setOccupationDetails(),
  ];
  const [oliuPhoneVerified, setOliuPhoneVerified] = [
    useSignupStore.use.oliuPhoneVerified(),
    useSignupStore.use.setOliuPhoneVerified(),    
  ];
  const [setEmail, setFirstName, setLastName, setAddress1, setAddress2, setCity, setPostalCode, setProvince, setBirthDay] = 
  [useSignupStore.use.setEmail(), useSignupStore.use.setFirstName(), useSignupStore.use.setLastName(),
    useSignupStore.use.setAddress1(), useSignupStore.use.setAddress2(), useSignupStore.use.setCity(),
    useSignupStore.use.setPostalCode(), useSignupStore.use.setProvince(), useSignupStore.use.setBirthDay()];
  const [setPurpose] = [ useSignupStore.use.setPurpose() ];
  const [loading, setIsLoading] = useState(false);
  const goBack = useSignupStore.use.prevState();

  const goToNextState = useSignupStore.use.nextState();

  const [errorMessage, setErrorMessage] = useState('');
  async function updateAddressInformation(e: FormEvent) {
    e.preventDefault();
    setIsLoading(true);
    const [birthYear, birthMonth, birthDate] = store.birthDay.split('-');
    const parsedBirthdate = [birthYear, birthMonth, birthDate].join('-');
    const { email, phoneNumber, firstName, lastName, address, purpose } = store;

    let localStorageVal = localStorage.getItem('email') || '';
    let emailVal = email || localStorageVal;
    let completeOccupation = '';
    if (occupation === 'Other' && occupationDetails) {
      completeOccupation = occupation + ':' + occupationDetails;
    } else {
      completeOccupation = occupation || '';
    }
    const { response, error } = await updateAndVerifyUser(
      emailVal,
      phoneNumber,
      firstName.trim(),
      lastName.trim(),
      parsedBirthdate,
      address.streetAddress1,
      address.streetAddress2,
      address.city,
      address.province as string,
      address.postalCode,
      completeOccupation,
      purpose || '',
      true, // PEP always true
      true, // TPID always true,
      import.meta.env.VITE_ENV_PARTNER_CODE
    );
    if (response) {
      setErrorMessage('');
      sessionStorage.setItem('oliuPhoneVerified', response?.data?.status.toString());
      localStorage.setItem('employmentStatus', employmentStatus || '');
      localStorage.setItem('occupation', occupation || '');
      if (occupation === 'Other') {
        localStorage.setItem('occupationDetails', occupationDetails || '');
      }
      localStorage.setItem('purpose', purpose || '');
      setOliuPhoneVerified(response?.data?.status);
      goToNextState();
      // set flow
      updateUserCurrentFlow(email, FormStates.OCCUPATION);      
      navigate('/SignupKYC');
    } else if (!error) {
      setErrorMessage('An unknown error occurred. Try again later');
    } else {
      setErrorMessage(error.message);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    if (localStorage.getItem('email')) {
      setEmail(localStorage.getItem('email') || '');
    }
    if (localStorage.getItem('firstName')) { 
      setFirstName(localStorage.getItem('firstName') || '');
    }
    if (localStorage.getItem('lastName')) { 
      setLastName(localStorage.getItem('lastName') || '');
    }
    if (localStorage.getItem('address1')) { 
      setAddress1(localStorage.getItem('address1') || '');
    }
    if (localStorage.getItem('address2')) { 
      setAddress2(localStorage.getItem('address2') || '');
    }
    if (localStorage.getItem('city')) { 
      setCity(localStorage.getItem('city') || '');
    }
    if (localStorage.getItem('postal')) { 
      setPostalCode(localStorage.getItem('postal') || '');
    }
    if (localStorage.getItem('province')) { 
      setProvince(localStorage.getItem('province') || '');
    }
    if (localStorage.getItem('dob1') && localStorage.getItem('dob2') && localStorage.getItem('dob3')) {
      setBirthDay(localStorage.getItem('dob1') + '-' + localStorage.getItem('dob2') + '-' + localStorage.getItem('dob3'));
    }    
    if (localStorage.getItem('employmentStatus')) {
      setEmploymentStatus(localStorage.getItem('employmentStatus') || '');
    }
    if (localStorage.getItem('occupation')) { 
      setOccupation(localStorage.getItem('occupation') || '');

      if (localStorage.getItem('occupationDetails') && occupation === 'Other') {
        setOccupationDetails(localStorage.getItem('occupationDetails') || '');
      }
    }
    if (localStorage.getItem('purpose')) { 
      setPurpose(localStorage.getItem('purpose') || '');
    }
  }, []);     

  const canSubmit = occupation && occupation?.length !== 0;
  const showOccupationList =
    employmentStatus === 'Employed' || employmentStatus === 'Self-Employed';
  const showOccupationInput = occupation?.startsWith('Other');

  if (loading) {
    return (
      <div className="my-auto flex justify-center">
        <LoadingIcon />
      </div>
    );
  }

  return (
    <form onSubmit={updateAddressInformation}>
      <div className="flex flex-col gap-3">
        <div className="text-lg font-bold uppercase">Employment Status</div>
        <EmploymentStatusSelector />
        {showOccupationList && (
          <div>
            <div className="text-lg font-bold uppercase">Occupation</div>
            <OccupationSelector />
          </div>
        )}

        {showOccupationInput && (
          <div>
            <div className="text-lg font-bold uppercase">
              Your occupation
            </div>
            <TextInput
              autoFocus
              placeholder="Job title"
              onChange={(e) => setOccupationDetails(e.target.value)}
            />
          </div>
        )}

        <div className="text-lg font-bold uppercase">
          Intended purpose of Account
        </div>
        <IntendedPurposeSelector />
        <div className='mb-[-20px]'>
        <LeftAlignSmallerText>
          By clicking continue, you are attesting to not be a Foreign or
          Domestic{' '}
          <a
            href={import.meta.env.VITE_PAYTRIE_PEP_URL}
            target="_blank"
            style={{ textDecoration: 'underline' }}
          >
            Politically Exposed Person
          </a>{' '}
          (PEP), a{' '}
          <a
            href={import.meta.env.VITE_PAYTRIE_HIO_URL}
            target="_blank"
            style={{ textDecoration: 'underline' }}
          >
            Head of an International Organization
          </a>{' '}
          (HIO), or a family member or close associate of a PEP or HIO. You are
          also attesting not to be transacting for a{' '}
          <a
            href={import.meta.env.VITE_PAYTRIE_TPD_URL}
            target="_blank"
            style={{ textDecoration: 'underline' }}
          >
            third party
          </a>
          .
        </LeftAlignSmallerText>
        </div>
        

        <Button type="submit" disabled={!canSubmit || loading}>
          Continue
        </Button>
        <ErrorBlockText>{errorMessage}</ErrorBlockText>
      </div>
    </form>
  );
}
