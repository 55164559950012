import { useProfileStore } from '../../../reducers';
import { ProfileField } from './ProfileField';
import { CopyIcon } from '@radix-ui/react-icons';

export const ReferralInfo = () => {
  const { referral } = useProfileStore();

  const handleCopy = () => {
    if (referral.link) {
      navigator.clipboard.writeText(referral.link).catch(() => console.error('Failed to copy'));
    }
  };

  return (
    <article className="grid gap-2">
      <div className="flex items-center justify-between">
        <ProfileField label="Referral Code" value={referral.code} className="w-full" />
      </div>
      <div className="flex items-center justify-between">
        <ProfileField label="Referral Credits" value={referral.credits?.toString()} className="w-full" />
      </div>
      <div className="relative flex items-center">
        <ProfileField label="Referral Link" value={referral.link} className="w-full" />
        <button
          onClick={handleCopy}
          className="absolute right-0 p-1 text-blue-500 hover:text-blue-700 dark:text-blue-300 dark:invert dark:hover:text-blue-500 flex items-center"
        >
          <CopyIcon className="w-5 h-5 dark:invert" />
        </button>
      </div>
    </article>
  );
};

export default ReferralInfo;
