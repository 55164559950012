import { MouseEventHandler, ReactNode } from 'react';
import { BackButton, PaytrieTopIcon } from '../../components/widget-components';
import clsx from 'clsx';
import {
  TransactionType,
  useBuySellStore,
} from '../../reducers/transactionReducer';

const Step = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={clsx(
        'grid-start flex gap-5 flex-col text-base font-normal leading-tight cursor-pointer mb-5',
        className
      )}
    >
      {children}
    </div>
  );
};

const StepHeader = ({ children }: { children: ReactNode }) => {
  return (
    <div className="font-bold uppercase leading-none tracking-wide">
      {children}
    </div>
  );
};

const StepContent = ({ children }: { children: ReactNode }) => {
  return <div className="text-base font-normal leading-tight">{children}</div>;
};

export const InfoPage = ({
  onHide,
  showBack,
}: {
  onHide: MouseEventHandler;
  showBack: boolean;
}) => {
  const transactionType = useBuySellStore.use.transactionType();
  return (
    <div
      className={
        showBack
          ? 'top-0 bottom-0 left-0 right-0 absolute dark:bg-stone-900 bg-cloud overflow-y-scroll p-5 z-10'
          : 'mt-4 relative'
      }
    >
      {showBack && (
        <BackButton aria-label="Go back" onClick={onHide}>
          &#x27f5;
        </BackButton>
      )}

      <PaytrieTopIcon className="absolute top-5 right-5 hidden md:block" />

      {transactionType === TransactionType.BUY && (
        <div>
          <div className="text-4xl font-normal leading-10 mb-12 mt-4">
            Buying Stablecoins
          </div>
          <div className="display flex gap-5">
            <div className="flex-1 w-1/2">
              <Step className="col-start-1">
                <StepHeader>Step 1</StepHeader>
                <StepContent>
                  Select network and token and enter the amount of stablecoins
                  you want to buy.
                </StepContent>
              </Step>
              <Step className="col-start-1">
                <StepHeader>Step 2</StepHeader>
                <StepContent>
                  Copy and paste your ETH or stablecoin wallet address into the
                  provided input box.
                </StepContent>
              </Step>
              <Step className="col-start-1">
                <StepHeader>Step 3</StepHeader>
                <StepContent>
                  Determine if you need some ETH in your wallet to help transfer
                  the stablecoins in the future. Highly recommended for new
                  users or new wallets.
                </StepContent>
              </Step>
              <Step className="col-start-1">
                <StepHeader>Step 4</StepHeader>
                <div>
                  Submit your transaction to PayTrie. This will lock in your
                  price.
                </div>
              </Step>
            </div>
            <div className="flex-1 w-1/2">
              <Step className="lg:col-start-2 lg:row-start-1">
                <div className="text-base font-bold uppercase leading-none tracking-wide">
                  Step 5
                </div>
                <StepContent>
                  Check your email inbox for a Request Money Transfer email from
                  Interac. Follow the instructions in the email to fund the
                  transaction with your bank account.
                </StepContent>
              </Step>

              <Step className="lg:col-start-2 lg:row-start-2">
                <StepHeader>Step 6</StepHeader>
                <StepContent>
                  Wait for PayTrie to accept the funds (up to 60 mins, you will
                  get an email notification when PayTrie accepts the funds)
                </StepContent>
              </Step>

              <Step className="lg:col-start-2 lg:row-start-3">
                <StepHeader>Step 7</StepHeader>
                <StepContent>
                  Recieve an email confirmation when the transaction is complete
                  and the stable coins are in your wallet
                </StepContent>
              </Step>
            </div>
          </div>
        </div>
      )}

      {transactionType === TransactionType.SELL && (
        <div>
          <div className="text-4xl font-normal leading-10 mb-12 mt-4">
            Selling Stablecoins
          </div>
          <div className="display flex gap-5">
            <div className="flex-1 w-1/2">
              <Step className="col-start-1">
                <StepHeader>Step 1</StepHeader>
                <StepContent>
                  Click the sell button in the top right corner of the widget to
                  flip between buy and sell.
                </StepContent>
              </Step>
              <Step className="col-start-1">
                <StepHeader>Step 2</StepHeader>
                <StepContent>
                  Select network and token and enter the amount of stablecoins
                  you want to sell.
                </StepContent>
              </Step>
              <Step className="col-start-1">
                <StepHeader>Step 3</StepHeader>
                <StepContent>
                  Copy and paste your ETH or stablecoin wallet address that you
                  will be sending from into the provided input box.
                </StepContent>
              </Step>
              <Step className="col-start-1">
                <StepHeader>Step 4</StepHeader>
                <div>
                  Submit your transaction to PayTrie. This will lock in your
                  price.
                </div>
              </Step>
            </div>
            <div className="flex-1 w-1/2">
              <Step className="lg:col-start-2 lg:row-start-1">
                <div className="text-base font-bold uppercase leading-none tracking-wide">
                  Step 5
                </div>
                <StepContent>
                  Using the wallet currently hodling the stablecoins, send the
                  specific amount to the address provided by Paytrie.
                </StepContent>
              </Step>

              <Step className="lg:col-start-2 lg:row-start-2">
                <StepHeader>Step 6</StepHeader>
                <StepContent>
                  Wait for PayTrie to accept the funds (up to 60 mins, you will
                  get an email notification when PayTrie accepts the funds)
                </StepContent>
              </Step>

              <Step className="lg:col-start-2 lg:row-start-3">
                <StepHeader>Step 7</StepHeader>
                <StepContent>
                  Have additional questions? Check out our FAQ or email us at
                  support@paytrie.com!
                </StepContent>
              </Step>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
