import { useState, PropsWithChildren, useEffect } from 'react';
import { Button } from '../../components/Button/Button';
import { ManualCheck } from './ManualCheck/ManualCheck';
import { OliuCheck } from './OliuCheck';
import { SoftCheck } from './SoftCheck';
import { useNavigate } from 'react-router-dom';
import * as Accordion from '@radix-ui/react-accordion';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { useSignupStore } from '../../reducers';

type VerificationTypes = 'MANUAL' | 'EQUIFAX' | 'OLIU_ID' | 'OLIU_BANK' | null;

interface AccordionItemProps extends PropsWithChildren {
  title: string;
}

const AccordionItem = ({ title, children }: AccordionItemProps) => {
  return (
    <Accordion.Item
      value={title}
      className="border rounded-md border-pebble dark:border-white mt-px overflow-hidden first:mt-0 focus-within:relative focus-within:z-10"
    >
      <Accordion.Header className="flex">
        <Accordion.Trigger className="text-black dark:text-white group flex h-[55px] flex-1 cursor-default items-center justify-between px-5 text-base leading-none border-b border-pebble/50 dark:border-white/50 outline-none font-bold uppercase">
          {title}
          <ChevronDownIcon
            className="text-black dark:text-white ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform duration-300 group-data-[state=open]:rotate-180"
            aria-hidden
          />
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content className="text-black dark:text-white data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden text-[15px]">
        {children}
      </Accordion.Content>
    </Accordion.Item>
  );
};

// TODO: get questions and allow user to answer them
export default function SignupKYCQuestions() {
  const navigate = useNavigate();
  const [verificationType, setVerificationType] =
    useState<VerificationTypes>(null);
  const enabledEquifax =
    import.meta.env.VITE_ENABLE_EQUIFAX_VERIFICATION === 'TRUE' ? true : false;
  const enabledManual =
    import.meta.env.VITE_ENABLE_MANUAL_VERIFICATION === 'TRUE' ? true : false;
  const enabledIdVerify =
    import.meta.env.VITE_ENABLE_OLIU_ID_VERIFICATION === 'TRUE' ? true : false;
  const enabledBankVerify =
    import.meta.env.VITE_ENABLE_OLIU_BANK_VERIFICATION === 'TRUE'
      ? true
      : false;
  const useIframe =
    import.meta.env.VITE_OLIU_MODE_IFRAME === 'TRUE' ? true : false;
  const setOliuVerifyType = useSignupStore.use.setOliuVerifyType();
  const [oliuPhoneVerified, setOliuPhoneVerified] = [
    useSignupStore.use.oliuPhoneVerified(),
    useSignupStore.use.setOliuPhoneVerified(),    
  ];

  const setEmail = useSignupStore.use.setEmail();
  const urlParams = new URLSearchParams(window.location.search);
  const emailParams = urlParams.get("email");
  if (emailParams) {
    console.log("OVERRIDE EMAIL IN STATE:", emailParams);
    setEmail(emailParams);
  }

  if (!verificationType) {
    return (
      <div className="flex flex-col gap-5">
        <div className="text-lg font-bold uppercase">Verification</div>
        <div className="text-base">
          Select the verification type you&apos;d like to use
        </div>
        <Accordion.Root
          className="w-full flex flex-col gap-5"
          type="single"
          collapsible
        >
          {enabledEquifax && (
            <AccordionItem title="EQUIFAX VERIFICATION">
              <div className="flex p-5">
                <div>
                  Answer multiple choice questions from your credit report to
                  verify your identity. Your credit score will not be impacted.
                  You must have 3+ years of credit history in Canada. This
                  secure process ensures that your information is never shared
                  with external sources.
                </div>
              </div>
              <Button
                type="button"
                style={{ display: 'block', width: '100%' }}
                onClick={() => setVerificationType('EQUIFAX')}
              >
                CONTINUE
              </Button>
            </AccordionItem>
          )}
          {enabledEquifax && (
            <div>or</div>
          )}
          {enabledManual && (
            <AccordionItem title="MANUAL VERIFICATION">
              <div className="flex p-5">
                <div>Placeholder text</div>
              </div>
              <Button
                type="button"
                style={{ display: 'block', width: '100%' }}
                onClick={() => setVerificationType('MANUAL')}
              >
                CONTINUE
              </Button>
            </AccordionItem>
          )}
          {enabledManual && (
            <div>or</div>
          )}
          {(enabledBankVerify && oliuPhoneVerified) && (
            <AccordionItem title="BANK VERIFICATION">
              <div className="flex p-5">
                <div>
                  Sign in to your bank to verify your identity. None of your
                  banking information will be shared with Paytrie. This secure
                  process ensures that your information is never shared with
                  external sources.
                </div>
              </div>
              <Button
                type="button"
                style={{ display: 'block', width: '100%' }}
                onClick={() => {
                  setOliuVerifyType('bank');
                  if (useIframe) {
                    navigate('/OliuIFrame');
                  } else {
                    setVerificationType('OLIU_BANK');
                  }
                }}
              >
                CONTINUE
              </Button>
            </AccordionItem>
          )}
          {(enabledBankVerify && oliuPhoneVerified) && (
            <div>or</div>
          )}
          {enabledIdVerify && (
            <AccordionItem title="ID VERIFICATION">
              <div className="flex p-5">
                <div>
                  Upload and verify your identity documents securely. This
                  secure process ensures that your information is never shared
                  with external sources.
                </div>
              </div>
              <Button
                type="button"
                style={{ display: 'block', width: '100%' }}
                onClick={() => {
                  setOliuVerifyType('document');
                  setVerificationType('OLIU_ID');
                }}
              >
                CONTINUE
              </Button>
            </AccordionItem>
          )}
        </Accordion.Root>
      </div>
    );
  }

  if (verificationType === 'MANUAL') {
    return <ManualCheck />;
  }

  if (verificationType === 'EQUIFAX') {
    return <SoftCheck />;
  }

  if (verificationType === 'OLIU_ID') {
    return <OliuCheck />;
  }

  if (verificationType === 'OLIU_BANK') {
    return <OliuCheck />;
  }
}
