interface ProfileFieldProps {
  label: string;
  value: string | undefined;
  className?: string;
}

export const ProfileField = ({ label, value, className }: ProfileFieldProps) => {
  return (
    <div className={`grid grid-cols-2 py-3 ${className}`}>
      <span className="font-bold text-gray-700 dark:text-white">{label}:</span>
      <span className="text-gray-900 dark:text-white break-words whitespace-normal">
        {value}
      </span>
    </div>
  );
};
