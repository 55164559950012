import { styled } from 'styled-components';
import * as ToggleGroup from '@radix-ui/react-toggle-group';
import {
  TransactionType,
  TransactionValue,
  useBuySellStore,
} from '../../reducers/transactionReducer';

const Toggle = styled(ToggleGroup.Root)`
  display: inline-flex;
  gap: 17px;
`;

// const ToggleItem = styled(ToggleGroup.Item)`
//   border-bottom: solid 2px transparent;
//   padding: 2px;
//   &[data-state='on'] {
//     border-bottom-color: var(--clementine);
//   }
// `;

const toggleFocusClass =
  'focus:outline-none focus:bg-neutral-200 dark:focus:bg-slate-600 border-b-transparent p-[2px] data-toggled:border-b-clementine dark:data-toggled:border-b-sun border-b-2';

export const BuySellSwitcher = () => {
  const transactionType = useBuySellStore.use.transactionType();
  const setTab = useBuySellStore.use.setTransactionType();
  return (
    <div>
      <Toggle
        className="text-base leading-tight"
        type="single"
        value={transactionType}
        aria-label="Text alignment"
        onValueChange={(val: TransactionValue) => {
          if (val) {
            setTab(val);
          }
        }}
      >
        <ToggleGroup.ToggleGroupItem
          className={toggleFocusClass}
          value={TransactionType.BUY}
          aria-label="Buy"
        >
          Buy
        </ToggleGroup.ToggleGroupItem>
        <ToggleGroup.ToggleGroupItem
          value={TransactionType.SELL}
          aria-label="Sell"
          className={toggleFocusClass}
        >
          Sell
        </ToggleGroup.ToggleGroupItem>
      </Toggle>
    </div>
  );
};
