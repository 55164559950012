import styled from 'styled-components';
import PanelBackgroundImage from '../assets/Paytrie_Patterns_arrow_snow.png';
import PoweredByImage from '../assets/powered-by-label.svg';
import InteracLogo from '../assets/interac.png';
import PaytrieIcon from '../assets/paytrie-nav-hr.svg';
import { InputHTMLAttributes, ReactNode, useState } from 'react';
import clsx from 'clsx';
import { Pages, usePageStateStore, useSignupStore } from '../reducers';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HamburgerMenuIcon, MoonIcon, SunIcon } from '@radix-ui/react-icons';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
// import darkModeMoon from '../assets/darkModeMoon.svg'
// import lightModeSun from '../assets/lightModeSun.svg'

export const RowAlign = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  gap: 5px;
`;

export const BackButton = styled.button`
  font-size: 20px;
  text-align: left;
  outline-offset: 4px;
`;

export const LogoutButton = styled.button`
  font-size: 20px;
  text-align: right;
  outline-offset: 4px;
  padding-top: 20px;
`;

export const StrikeLine = styled.div`
  content: '';
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
`;

export const LineThroughText = styled.div`
  width: 100%;
  font-size: 0.5em;
  flex-direction: row;
  padding-top: 1em;
  padding-bottom: 1em;
  display: flex;
  flex: 1;
  &:before,
  &:after {
    flex: 1;
    content: '';
    border-top: 1px solid;
    height: 0;
    margin: 5px;
  }
`;

export const SmallText = styled.div`
  text-align: right;
  font-size: 12px;
`;

export const LeftAlignSmallText = styled.div`
  text-align: left;
  font-size: 12px;
  padding-bottom: 4em;
`;

export const LeftAlignSmallerText = styled.div`
  text-align: left;
  font-size: 10px;
  padding-bottom: 4em;
`;

export const CenterAlignedSmallText = styled.div`
  text-align: center;
  font-size: 12px;
  padding-bottom: 4em;
`;

export const TocSmallText = styled.div`
  text-align: left;
  font-size: 10px;
`;

export const NoAccountText = styled(SmallText)`
  padding-bottom: 4em;
  text-align: left; 
`;

export const PoweredBy = styled(SmallText)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const TopIcon = styled.div`
  padding-top: 30px;
  padding-bottom: 100px;
  height: 22px;
  width: 100%;
  display: flex;
  text-align: right;
  margin-right: 0px;
  flex-direction: row-reverse;
`;

export const Header2 = styled.div`
  color: #fff;
  /* web/subhead */

  font-family: 'PP Object Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 110%;
  padding-bottom: 12px;
  /* or 18px */

  letter-spacing: 0.07em;
  text-transform: uppercase;
  font-feature-settings: 'ss01' on;
`;

export const LeftAlign = styled.div`
  text-align: left;
  align: left;
`;

export const BlockText = styled.div`
  font-family: 'PP Object Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */

  font-feature-settings: 'ss01' on;

  /* web/ snow */

  color: #ffffff;
`;

export const ErrorBlockText = styled(BlockText)`
  color: #f33;
`;

export const InputText = styled.input`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  margin-top: 10px;
  padding-bottom: 4px;
  width: 100%;
  background-color: transparent;
  gap: 14px;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid var(--rock);
  height: 32px;
  font-family: 'PP Object Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  user-select: none;
  box-shadow: none;
  outline: none;
  &:invalid {
    border-color: red;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    transition:
      background-color 0s 600000s,
      color 0s 600000s !important;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export interface CheckboxProps {
  labelText: string;
  isChecked: boolean;
  setIsChecked: (arg0: boolean) => void;
}

export function Checkbox({
  labelText,
  isChecked,
  setIsChecked,
}: CheckboxProps) {
  return (
    <BlockText>
      <label>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => {
            setIsChecked(!isChecked);
          }}
        />
        {labelText}
      </label>
    </BlockText>
  );
}

export type TextInputProps = {
  placeholder?: string;
  pattern?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export function TextInput({ placeholder, pattern, ...props }: TextInputProps) {
  return (
    <InputText
      type={pattern}
      placeholder={placeholder}
      {...props}
      className={clsx(
        props.className,
        'text-pebble dark:text-snow focus:text-clementine dark:focus:text-sun'
      )}
    />
  );
}

export function PoweredByLabel() {
  return (
    <PoweredBy>
      <div>
        <img
          src={PoweredByImage}
          height={26}
          className="dark:invert invert-0 h-[26px]"
        />
      </div>
    </PoweredBy>
  );
}

export function InteracLabel() {
  return (
    <PoweredBy>
      <div className="text-gravel dark:text-rock" style={{ fontSize: '16px' }}>
        Payment processed by
      </div>
      <div className="m-2">
        <img width={40} src={InteracLogo} />
      </div>
    </PoweredBy>
  );
}

export function PaytrieTopIcon({ className }: { className?: string }) {
  return (
    <img
      src={PaytrieIcon}
      className={clsx('h-[25px] w-[25px]', 'dark:invert invert-0', className)}
    />
  );
}

export const PanelBackground = styled.div`
  position: relative;
  &:after {
    background: url(${PanelBackgroundImage}) no-repeat;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    background-size: cover; 
    background-position: center; 
  }
`;

export const panelClass = clsx([
  'bg-center',
  'h-[100px]',
  'items-center',
  'md:items-start',
  'md:min-h-[700px]',
  'md:h-max',
  'flex',
  'flex-row',
  'md:flex-col',
  'justify-between',
  'md:justify-start',
  'md:gap-10',
  'md:py-10',
  'px-5',
  'md:w-[350px]',
  'md:min-w-[350px]',
  'after:invert after:dark:invert-0',
]);

export const LoginHeader = ({ children }: { children: ReactNode }) => {
  const reset = useSignupStore.use.reset();
  const navigate = useNavigate();

  useEffect(() => {
    // reset the form on unmount
    return () => reset();
  }, []);

  return (
    <PanelBackground className={panelClass}>
      <BackButton
        onClick={() => {
          navigate(-1);
        }}
        className="absolute top-8 md:top-5 left-5 md:left-10"
      >
        &#x27f5;
      </BackButton>
      <div className="hidden md:block py-5 md:py-10 md:px-4">{children}</div>
      <PaytrieTopIcon className="absolute top-8 right-5 md:hidden" />
    </PanelBackground>
  );
};
export const ProfileHeader = () => {
  const navigate = useNavigate();
  return (
    <PanelBackground
      className={`${panelClass} relative gap-10 items-center w-full`}
    >
      <BackButton
        onClick={() => {
          navigate('/');
        }}
        className="absolute top-8 md:top-5 left-5 md:left-10"
      >
        &#x27f5;
      </BackButton>

      <PaytrieTopIcon className="absolute top-8 right-5 md:hidden" />
    </PanelBackground>
  );
};

export const EllipsisAnimation = styled.div`
  position: relative;
  &:after {
    position: absolute;
    display: inline-block;
    animation: dotty steps(1, end) 1s infinite;
    content: '';
  }

  @keyframes dotty {
    0% {
      content: '';
    }
    25% {
      content: '.';
    }
    50% {
      content: '..';
    }
    75% {
      content: '...';
    }
    100% {
      content: '';
    }
  }
`;

const DropdownMenuContentTag = styled(DropdownMenu.Content)`
  min-width: 220px;
  background-color: white;
  padding: 5px;
  box-shadow:
    0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  dark: invert;
`;

export const DropdownMenuSelect = () => {
  const navigate = useNavigate();
  const clearAuthToken = usePageStateStore.use.clearAuthToken();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const getInitialMode = () => {
    const savedMode = localStorage.getItem('darkMode');
    if (savedMode !== null) {
      return savedMode === 'true';
    }
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  };

  const [darkMode, setDarkMode] = useState(getInitialMode);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }

    const handleMessageFromParent = (event: {
      data: { darkMode: boolean | ((prevState: boolean) => boolean) };
    }) => {
      if (event.data && typeof event.data.darkMode === 'boolean') {
        setDarkMode(event.data.darkMode);
        localStorage.setItem('darkMode', String(event.data.darkMode));
      }
    };

    window.addEventListener('message', handleMessageFromParent);
    return () => window.removeEventListener('message', handleMessageFromParent);
  }, [darkMode]);

  useEffect(() => {
    const authToken =
      localStorage.getItem('authToken') || sessionStorage.getItem('authToken');
    setIsLoggedIn(!!authToken);
  }, []);

  useEffect(() => {
    const syncDarkModeAcrossTabs = (event: StorageEvent) => {
      if (event.key === 'darkMode') {
        setDarkMode(event.newValue === 'true');
      }
    };
    window.addEventListener('storage', syncDarkModeAcrossTabs);
    return () => {
      window.removeEventListener('storage', syncDarkModeAcrossTabs);
    };
  }, []);

  const handleSignOut = () => {
    clearAuthToken();
    setIsLoggedIn(false);
    // navigate('/');
    window.location.href = import.meta.env.VITE_APPLICATION_URL;
  };

  const toggleDarkMode = () => {
    const newMode = !darkMode;
    setDarkMode(newMode);
    localStorage.setItem('darkMode', String(newMode));
    window.parent.postMessage({ darkMode: newMode }, '*');
  };

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
    window.addEventListener('message', (event) => {
      if (event.data && typeof event.data.darkMode === 'boolean') {
        const darkMode = event.data.darkMode;
        document.documentElement.classList.toggle('dark', darkMode);
        localStorage.setItem('darkMode', String(darkMode));
      }
    });
  }, [darkMode]);

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button className="IconButton " aria-label="customize options">
          <HamburgerMenuIcon className="w-8 h-8" />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="bg-white dark:bg-pebble min-w-[220px] p-3 shadow-md"
          style={{
            borderRadius: '0',
            boxShadow:
              '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
            animationDuration: '400ms',
            animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
            textAlign: 'left',
          }}
          sideOffset={5}
        >
          {!isLoggedIn ? (
            <>
              <DropdownMenu.Item
                className="DropdownMenuItem my-1"
                style={{ cursor: 'pointer' }}
                onSelect={() => {
                  navigate('/EasyLogin');
                }}
              >
                Sign in
              </DropdownMenu.Item>

              <DropdownMenu.Item
                className="DropdownMenuItem my-1"
                style={{ cursor: 'pointer', textAlign: 'left' }}
                onSelect={() => navigate('/SignupEmail')}
              >
                Sign up
              </DropdownMenu.Item>
            </>
          ) : (
            <DropdownMenu.Item
              className="DropdownMenuItem my-1"
              style={{ cursor: 'pointer', textAlign: 'left' }}
              onSelect={handleSignOut}
            >
              Sign out
            </DropdownMenu.Item>
          )}

          <DropdownMenu.CheckboxItem
            style={{ cursor: 'pointer', textAlign: 'left' }}
            className="DropdownMenuCheckboxItem my-1"
            checked={darkMode}
            onCheckedChange={toggleDarkMode}
          >
            {darkMode ? (
              <SunIcon className="w-5 h-5" />
            ) : (
              <MoonIcon className="w-5 h-5" />
            )}
          </DropdownMenu.CheckboxItem>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
export default DropdownMenuSelect;
