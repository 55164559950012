import { useProfileStore } from '../../../reducers';
import { ProfileField } from './ProfileField';

export const PersonalInfo = () => {
  const {
    firstName,
    lastName,
    email,
    birthDay,
    birthMonth,
    birthYear,
    address,
    phoneNumber,
  } = useProfileStore();
  
  return (
    <article className="grid gap-2">
      <ProfileField label="First Name" value={firstName} />
      <ProfileField label="Last Name" value={lastName} />
      <ProfileField label="Email" value={email} />
      <ProfileField label="Phone Number" value={phoneNumber} />
      <ProfileField
        label="Birthday"
        value={`${birthDay}/${birthMonth}/${birthYear}`}
      />
      <ProfileField label="Address" value={address.streetAddress1} />
      <ProfileField label="Province" value={address.province ?? ''} />
      <ProfileField label="City" value={address.city} />
      <ProfileField label="Postal Code" value={address.postalCode} />
    </article>
  );
};

export default PersonalInfo;
