import clsx from 'clsx';
import {
  BackButton,
  PanelBackground,
  PaytrieTopIcon,
  panelClass,
} from '../../components/widget-components';
import { Pages, usePageStateStore } from '../../reducers';
import { useNavigate } from 'react-router-dom';

const titleText = clsx('text-md md:text-[48px] leading-tight');

export default function PaytrieWelcomeLeftPanel() {
  const goToPage = usePageStateStore.use.goToPage();
  const page = usePageStateStore.use.page();
  const navigate = useNavigate();
  return (
    <PanelBackground className={panelClass}>
      <BackButton
        onClick={() => {
          //console.log("PAGE:", page);
          if (window.location.pathname === "/Welcome") {
            //goToPage(Pages.CALC);
            navigate("/");
          } else {
            //goToPage(Pages.WELCOME);
            navigate("/")
          }
        }}
        className="absolute top-8 md:top-5 left-5 md:left-10"
      >
        &#x27f5;
      </BackButton>
      <div className="hidden md:block py-5 md:py-10 md:px-4">
        <h2 className={clsx(titleText, 'dark:text-sun text-clementine')}>
          Sign in to Paytrie,
        </h2>
        <div className={titleText}>Canada&apos;s home for stablecoins</div>
      </div>
      <PaytrieTopIcon className="absolute top-8 right-5 md:hidden" />
    </PanelBackground>
  );
}
