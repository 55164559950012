import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getOrderStatusByTransactionId, OrderStatusResponse } from '../../requests/order';
import PaytrieIcon from '../../assets/paytrie-full-rgb.svg';
import InteracLogo from '../../assets/interac-circle.png'; 
import { WidgetLayout } from '../Buy/WidgetLayout';
import './OrderStatus.css'
import { useBuySellStore} from '../../reducers/transactionReducer';
import { DarkModeMenu } from 'src/panels/signup/DarkmodeSignUpPanel';

const OrderStatus = () => {
  const [searchParams] = useSearchParams();
  const transactionId = searchParams.get('id');

  const [orderDetails, setOrderDetails] = useState<OrderStatusResponse | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [reset] = [useBuySellStore.use.reset()];

  useEffect(() => {
    if (transactionId) {
      fetchOrderDetails(transactionId);
    }
    const interval = setInterval(() => {
      if (transactionId) {
        // reset();
        fetchOrderDetails(transactionId);
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [transactionId]);

  const fetchOrderDetails = async (transactionId: string) => {
    try {
      const response = await getOrderStatusByTransactionId(transactionId);
      setOrderDetails(response.data);
    } catch (error) {
      setError('Failed to fetch order details');
    }
  };

  const getStatusLabel = (status: string) => {
    const lowerStatus = status.toLowerCase();

    if (lowerStatus.includes('pending')) {
      return 'Pending e-transfer payment';
    } else if (lowerStatus.includes('complete')) {
      return 'Complete';
    } else if (lowerStatus.includes('cancelled')) {
      return 'Cancelled';
    } else if (lowerStatus.includes('expired')) {
      return 'Expired';
    } else if (lowerStatus.includes('waiting')) {
      return 'Waiting for payment';
    } else if (lowerStatus.includes('processing')) {
      return 'Processing request money transfer';
    } else if (lowerStatus.includes('sending')) {
      return 'Sending funds';
    } else {
      return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    }    
  };

  const truncateMiddle = (str: string, length: number) => {
    if (str.length <= length) {
      return str;
    }
    const frontLength = Math.ceil(length / 2);
    const backLength = Math.floor(length / 2);
    return `${str.substr(0, frontLength)}...${str.substr(str.length - backLength)}`;
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };
    return date
      .toLocaleDateString('en-US', options)
      .replace(/, /g, ' ')
      .replace(/(\d{4})/, '$1,'); 
  };

  const getConfirmationUrl = () => {
    if (!orderDetails) return 'https://etherscan.io/';

    const { leftSideLabel, rightSideLabel } = orderDetails;

    if (['USDT-BSC', 'BUSD-BSC', 'USDC-BSC', 'USDT-B', 'BUSD-B'].includes(leftSideLabel) ||
        ['USDT-BSC', 'BUSD-BSC', 'USDC-BSC', 'USDT-B', 'BUSD-B'].includes(rightSideLabel)) {
      return 'https://bscscan.com/';
    } else if (['USDC-POLY', 'CADC-POLY', 'USDC-P', 'CADC-P'].includes(leftSideLabel) ||
               ['USDC-POLY', 'CADC-POLY', 'USDC-P', 'CADC-P'].includes(rightSideLabel)) {
      return 'https://polygonscan.com/';
    } else if (leftSideLabel === 'USDC-ARB' || rightSideLabel === 'USDC-ARB') {
      return 'https://arbiscan.io/';
    } else if (leftSideLabel === 'USDC-OP' || rightSideLabel === 'USDC-OP') {
      return 'https://optimistic.etherscan.io/';
    } else if (leftSideLabel === 'USDC-AVAX' || rightSideLabel === 'USDC-AVAX') {
      return 'https://snowtrace.io/';
    } else if (leftSideLabel === 'USDC-FTM' || rightSideLabel === 'USDC-FTM') {
      return 'https://ftmscan.com/';
    } else if (leftSideLabel === 'USDC-GNO' || rightSideLabel === 'USDC-GNO') {
      return 'https://gnosisscan.io/';
    } else if (leftSideLabel === 'USDC-CELO' || rightSideLabel === 'USDC-CELO') {
      return 'https://celoscan.io/';
    } else if (leftSideLabel === 'USDC-SX' || rightSideLabel === 'USDC-SX') {
      return 'https://explorer.sx.technology/';
    } else if (leftSideLabel === 'USDC-BASE' || rightSideLabel === 'USDC-BASE') {
      return 'https://basescan.org/';
    } else if (leftSideLabel === 'USDC-SOL' || rightSideLabel === 'USDC-SOL') {
      return 'https://solscan.io/';
    } else {
      return 'https://etherscan.io/';
    }
  };

  const confirmationUrl = getConfirmationUrl();
  const isInIframe = window.self !== window.top;
  let isParentDashboardPage = false;

  if (isInIframe) {
    try {
      isParentDashboardPage = window.parent.location.pathname.includes('/dashboard');
    } catch (error) {
      console.error("Unable to access parent location. Ensure the iframe and parent page are on the same origin.", error);
    }
  }

  const getProgressBar = (status: string) => {
    const lowerStatus = status.toLowerCase();
    if (lowerStatus.includes('complete')) {
      return { percent: 100, label: "100%", progressClass: "bg-green-500" };
    } else if (lowerStatus.includes('waiting')) {
      return { percent: 50, label: "50%", progressClass: "bg-green-500" };
    } else if (lowerStatus.includes('pending')) {
      return { percent: 60, label: "60%", progressClass: "bg-green-500" };
    } else if (lowerStatus.includes('processing')) {
      return { percent: 70, label: "70%", progressClass: "bg-green-500" };
    } else if (lowerStatus.includes('sending')) {
      return { percent: 80, label: "80%", progressClass: "bg-green-500" };
    } else if (lowerStatus.includes('cancelled') || lowerStatus.includes('expired')) {
      return { percent: 100, label: "100%", progressClass: "bg-gray-500" };
    } else {
      return null;
    }
  };  

  const progressBar = orderDetails ? getProgressBar(orderDetails.status) : null;

  return (
    <WidgetLayout showInteracLabel={false} showPoweredByLabel={true}>
    <div className="order-status-container flex items-center justify-center">
        {isInIframe && !isParentDashboardPage && (
      <div className="absolute top-10 right-0">
        <DarkModeMenu showSignOutButton={true} />
      </div>
    )}
      <div className="w-full max-w-lg px-4 sm:px-6 dark:text-white" style={{ position: 'absolute', top: !isInIframe ? '10%' : '9%', width: '100%'}}>
        {/* <div className="flex items-center justify-center mb-4">
          <img src={PaytrieIcon} alt="PayTrie Logo" className="mr-2 dark:invert" style={{ width: '200px', height: 'auto' }} />
        </div> */}
        <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-center text-black dark:text-white">ORDER STATUS</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {orderDetails ? (
          <div className="px-4 sm:px-6">
            <h3 className="text-xl sm:text-2xl font-semibold mb-10 text-center text-black dark:text-white">
              {getStatusLabel(orderDetails.status)}
            </h3>
            {(orderDetails.status.toLowerCase().includes('pending') || 
              (orderDetails.status.toLowerCase().includes('waiting') && orderDetails.leftSideLabel === 'CAD')) ? (
              <div className="flex items-center justify-center mb-10" style={{ height: '3rem' }}>
                <div className="w-full flex justify-center">
                  <a
                    className="bg-black text-white dark:bg-semiWhite dark:text-black py-6 px-4 border-black w-full sm:w-5/6 border-2 items-center text-center"
                    href={`https://etransfer.interac.ca/reqPayment/eml/${orderDetails.atb_status}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PAY WITH
                    <img src={InteracLogo} alt="Interac Logo" className="inline-block ml-2" style={{ width: '24px', height: '24px' }} />
                  </a>
                </div>
              </div>
            ) : progressBar ? (
              <div className="mb-10">
                <div className="relative pt-1">
                  <div className="overflow-hidden h-2 mb-10 text-xs flex rounded bg-green-200">
                    <div style={{ width: `${progressBar.percent}%` }} className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${progressBar.progressClass}`}></div>
                  </div>
                </div>
              </div>
            ) : null}
           {['pending', 'complete', 'cancelled', 'expired', 'waiting', 'processing', 'sending'].some(keyword => orderDetails.status.toLowerCase().includes(keyword)) ? (
              <div className="space-y-2 px-4 sm:px-6">
                <div className="flex justify-between text-black dark:text-white">
                  <span className="font-semibold min-w-[100px]">Date:</span>
                  <span className="text-right ml-4 flex-grow">{formatDate(orderDetails.date)}</span>
                </div>
                {orderDetails.status.toLowerCase().includes('complete') && orderDetails.payment_id && (
                  <div className="flex justify-between text-black dark:text-white">
                    <span className="font-semibold min-w-[100px]">Confirmation:</span>
                    <span className="text-right ml-4 flex-grow break-all">
                      <a
                        href={`${confirmationUrl}tx/${orderDetails.payment_id}`}
                        rel="nofollow noopener noreferrer"
                        target="_blank"
                        className="text-blue-500 break-all"
                      >
                        {truncateMiddle(orderDetails.payment_id, 20)}
                      </a>
                    </span>
                  </div>
                )}
                <div className="flex justify-between text-black dark:text-white">
                  <span className="font-semibold min-w-[100px]">Tx&nbsp;ID:</span>
                  <span className="text-right ml-4 flex-grow break-all">
                    {orderDetails.tx_id}
                  </span>
                </div>
                <div className="flex justify-between text-black dark:text-white">
                  <span className="font-semibold min-w-[100px]">You pay:</span>
                  <span className="text-right ml-4 flex-grow">{parseFloat(orderDetails.leftSideValue).toFixed(2)} {orderDetails.leftSideLabel}</span>
                </div>
                <div className="flex justify-between text-black dark:text-white">
                  <span className="font-semibold min-w-[100px]">You receive:</span>
                  <span className="text-right ml-4 flex-grow">{parseFloat(orderDetails.rightSideValue).toFixed(2)} {orderDetails.rightSideLabel}</span>
                </div>
                <div className="flex justify-between text-black dark:text-white">
                  <span className="font-semibold min-w-[100px]">Wallet:</span>
                  <span className="text-right ml-4 flex-grow break-all">
                    <a
                      href={`${confirmationUrl}address/${orderDetails.wallet}#tokentxns`}
                      rel="nofollow noopener noreferrer"
                      target="_blank"
                      className="text-blue-500 break-all"
                    >
                      {orderDetails.wallet}
                    </a>
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <p className="text-black dark:text-white">Loading...</p>
        )}
      </div>
    </div>
    </WidgetLayout>
  );
};

export default OrderStatus;
